import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

// material-ui
import { Grid } from "@mui/material";

import { useAsyncNew } from 'hooks/useAsyncNew';
import NetworkAppsService from 'services/network-apps/NetworkApps';
import { OneChipInfoProvider, useOneChipInfoContext } from 'contexts/OneChipInfoContext';
import PlanFilters from 'modules/networksapps/PlanFilters';
import DataComponent from 'modules/networksapps/DataComponent';
import { SvgIcon } from '@mui/material';
import { ReactComponent as PublicIcon } from 'assets/icons/assign_public_icon.svg';
import { ReactComponent as PrivateIcon } from 'assets/icons/assign_private_icon.svg';
import { AssignButtonContainer } from 'modules/onechip-details/meerkat/containers/AssignContainer';
import LoadingButton from '@mui/lab/LoadingButton';
import { useModal } from 'components/shared/modal';

import PlanDetailsContainer from 'modules/modals/PlanDetails/containers/PlanDetailsContainer';
import InfinityScrollWrapper from 'modules/modals/SelectPlan/components/InfinityScrollContainer';
import { useTranslation } from 'react-i18next';
import withEmptyData from 'hoc/witEmptyData';



const MeerkatSelectPlan = ({ onComplete = () => { } }) => {

    const modal = useModal({ stacked: false });

    const { t } = useTranslation();

    const { info } = useOneChipInfoContext();
    const infoData = info.data;
    const { meerkatPublicVpnEnabled = false,
        meerkatPrivateVpnEnabled = false,
        eid = null
    } = infoData?.esim ?? {};
    const additionPlanParams = {
        meerkatPublicVpnEnabled,
        meerkatPrivateVpnEnabled,
        eid
    }

    const defaultFilters = {
        service: ['meerkat'],
    }
    const [filters, setFilters] = useState({
        limit: 10,
        offset: 0,
        ...defaultFilters
    });
    const { execute, data, isSuccess, isLoading } = useAsyncNew(NetworkAppsService.getPlansList, { immediate: false });

    const [isNewData, setIsNewData] = useState(true);

    useEffect(() => {
        info.stop();
    }, []);

    useEffect(() => {
        if (info.isSuccess) {
            execute({ ...filters, sort: 'name:asc', clientId: infoData?.esim?.clientId });
        }
    }, [filters, info.isSuccess])

    const handleOpenPlanDetailsModal = (data) => {
        modal.openModal(PlanDetailsContainer,
            {
                data: { ...data, eid: infoData.esim.eid },
                isMeerkat: true,
                onComplete
            },
            {
                title: [{ name: 'MeerkatPlanSelectionModal', title: t('views.onechip_details.meerkat.actions.add_app') }, { name: '', title: data.planName }],
                fullWidth: true,
                maxWidth: "md",
                close: true,
                sx: {
                    '& .ModalContentWrapper-root': {
                        backgroundColor: '#F5F5F5'
                    }
                }
            });
    }

    const handleOnFilter = (filters) => {
        const newFilters = { ...filters, offset: 0 };
        setFilters(prev => ({ ...prev, ...newFilters }));
        setIsNewData(true);
    }

    const handleFetchMore = useCallback(() => {
        setIsNewData(false);
        setFilters(prev => ({ ...prev, offset: prev.offset + prev.limit }));
    }, [])

    return (
        <>
            <PlanFilters
                onFilter={handleOnFilter}
                isModal={true}
                readOnly={{
                    service: true,
                }}
                selected={defaultFilters}
            />
            <InfinityScrollWrapper
                data={data}
                isLoading={isLoading}
                onFetchMore={handleFetchMore}
                isNew={isNewData}
                isSuccess={isSuccess}
            >
                {(state) =>
                    <Grid container
                        columnSpacing={2}
                        alignItems={'right'}
                        rowSpacing={{ xs: 2 }}
                        sx={{ pt: 0, mt: 0 }}
                    >
                        {state.items.map((plan, index) => (
                            <Grid item xs={6} key={`grid-${plan._id}`} >
                                <DataComponent
                                    key={`tile-${plan._id}`}
                                    isRecommendation={false}
                                    item={plan}
                                    onClick={() => handleOpenPlanDetailsModal(plan)}
                                >
                                    <AssignButtonContainer modal={modal} key={'public'} data={{ ...additionPlanParams, ...plan }} type='public' onComplete={onComplete}>
                                        {({ onAssign, isLoadingAssign }) =>
                                            <LoadingButton
                                                variant="contained"
                                                fullWidth
                                                startIcon={<SvgIcon component={PublicIcon} inheritViewBox />}
                                                onClick={onAssign}
                                                loading={isLoadingAssign}
                                                disabled={isLoadingAssign}
                                            >
                                                Assign Public IP
                                            </LoadingButton>
                                        }
                                    </AssignButtonContainer>
                                    <AssignButtonContainer modal={modal} key={'private'} data={{ ...additionPlanParams, ...plan }} type='private' onComplete={onComplete}>
                                        {({ onAssign, isLoadingAssign }) =>
                                            < LoadingButton
                                                variant="outlined"
                                                fullWidth
                                                startIcon={<SvgIcon component={PrivateIcon} sx={{ fill: 'none' }} inheritViewBox />}
                                                onClick={onAssign}
                                                loading={isLoadingAssign}
                                                disabled={isLoadingAssign}
                                            >
                                                Assign Private IP
                                            </LoadingButton>
                                        }
                                    </AssignButtonContainer>
                                </DataComponent>
                            </Grid>
                        ))}
                    </Grid>
                }
            </InfinityScrollWrapper>
        </>
    )
}

const withOnechipInfoProvider = (Component) => {
    return (props) => (
        <OneChipInfoProvider>
            <Component {...props} />
        </OneChipInfoProvider>
    )
}

export default withOnechipInfoProvider(withEmptyData(MeerkatSelectPlan));

const MeerkatModalChain = (breadcrumbs = []) => {

    const modal = useModal({ stacked: false });

    const [index, setIndex] = useRef(0);
    const modals = [
        MeerkatSelectPlan,
        PlanDetailsContainer
    ];

    const next = (props = {}, options = {}) => {
        modal.openModal(modals[index.current++], props, options);
    }

    const Component = modals[index];

    return <Component {...props} next={next} />
}