import PropTypes from 'prop-types';

// material-ui
import { Typography } from '@mui/material';

import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material';
import React, { Children, useState } from 'react';

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 250,
        position: "relative",
        paddingRight: "30px",
        marginLeft: "-16px"
    },

    [`& .${tooltipClasses.arrow}`]: {
        marginLeft: "5px"
    }

});

const CustomTooltip = React.forwardRef((props, ref) => {

    const {
        title = '',
        children,
        disableHoverListener = true,
        showClose = false,
        ...restProps
    } = props;

    return (
        <>
            <CustomWidthTooltip
                {...restProps}
                disableFocusListener={false}
                disableHoverListener={disableHoverListener}
                disableTouchListener={false}
                title={title}
            >
                {children}
            </CustomWidthTooltip>
        </>
    )

});

export default CustomTooltip;