import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Grid, Modal, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import MainCard from "components/MainCard";
import ImageContainer from "modules/networksapps/ImageContainer";
import KeyValueList from "components/shared/list/KeyValueList";
import CoverageMap from "modules/networksapps/CoverageMap";
import TokenService from "services/token/Token";
import { useTranslation } from "react-i18next";
import { UnitLabels } from "const/UnitLabels";
import PlanInstall from "modules/modals/PlanDetails/components/PlanInstallAction";
import TruncateList from "components/shared/list/TruncateList";
import withEmptyData from "hoc/witEmptyData";

const styles = {
  modalStyles: {
    width: "100%",

    background: "#F4F5F5",
    borderRadius: 8,
    outline: "none",
    overflow: "auto"
  },
  typographyStyles: {
    width: "211px",
    height: "29px",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "29px",
    letterSpacing: "0px",
    textAlign: "left"
    /* Add any additional styles here */
  },
  detailsHeaderStyles: {
    width: "62px",
    height: "22px",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "22px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#111",
    whiteSpace: "nowrap"
    /* Add any additional styles here */
  },
  detailsValueStyles: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "Normal",
    lineHeight: "22px",
    letterSpacing: "0px",
    textAlign: "left"
    /* Add any additional styles here */
  },

  closeButton: {
    // ... (other button styles)
    textDecoration: "none", // Add this line to remove underline
    "&:focus": {
      outline: "none"
    },
    "&:focus-visible": {
      boxShadow: "0 0 0 2px #aaa"
    }
  },
  pricePerMbStyles: {
    width: "135px",
    height: "31px",
    fontSize: "26px",
    fontWeight: 700,
    lineHeight: "31px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#008986" // Dark TEAL

    /* Add any additional styles here */
  },
  accessPriceStyles: {
    width: "148px",
    height: "19px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "19px",
    letterSpacing: "0px",
    textAlign: "left"
    /* Add any additional styles here */
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center"
  },
  checkbox: {
    marginRight: "0", // Remove right margin
    padding: "0"     // Remove padding
  }
};

const SkuList = ({
  data,
  limit = 4
}) => {
  const totalData = data.length;
  const renderVisible = (data, index, arr) => {

    const total = arr.length;
    let value = data;
    const lastIndex = Math.min(total, limit);
    if (index !== lastIndex - 1) {
      value += ', ';
      return value;
    }
    const hasMore = (totalData - total) > 0;
    if (index === lastIndex - 1 && total > 1 && hasMore) {
      value += '... ';
      return value;
    }

    return value;
  }

  const renderHidden = (data, index, arr) => {
    const total = arr.length - 1;
    let value = data;
    if (total >= 1 && index < total) {
      value += ', ';
    }
    return value;
  }
  return <TruncateList
    limit={limit}
    data={data}
    renderVisible={renderVisible}
    renderHidden={renderHidden}
    transform={(data) => data.sku}
    defaultValue={'-'}
    sx={{
      '& .TruncateListHiddenCount': {
        color: '#00579F'
      }
    }}
  />

}


const PlanDetails = ({ components = {}, componentProps = {}, item }) => {

  const { actions: Actions = () => PlanInstall } = components;
  const { actions: actionsProps = {} } = componentProps

  const tokenService = TokenService.getData();
  const { t } = useTranslation();

  const [showCoverageMap, setShowCoverageMap] = useState(true);


  const planDescriptionInfo = useMemo(() => [
    { name: t('views.plan_details.plan_description_info.rat'), value: item?.networkTechTypes?.join("/") ?? "-" },
    { name: t('views.plan_details.plan_description_info.credential_type'), value: item?.credentialsType ?? "-" },
    { name: t('views.plan_details.plan_description_info.coverage_type'), value: item?.coverageType ?? "-" },
    { name: t('views.plan_details.plan_description_info.mcc_mnc'), value: "-" },
    { name: t('views.plan_details.plan_description_info.network_type'), value: item?.networkType ?? "-" },
    { name: t('views.plan_details.plan_description_info.smsc'), value: "-" },
    {
      name: t('views.plan_details.plan_description_info.sku'), value: () => {
        return <SkuList data={item.storeUnits} />
      }
    },
    { name: t('views.plan_details.plan_description_info.network_app_uuid'), value: item?.planUuid || "-" }
  ], [item]);

  const planPriceInfo = useMemo(() => [
    { name: t('views.plan_details.plan_price_info.overage'), value: "-" },
    { name: t('views.plan_details.plan_price_info.sms_mt'), value: tokenService.data.hidePlanPrice ? ' -' : `$${item?.smsPrice ?? '-'}` },
    { name: t('views.plan_details.plan_price_info.sms_mo'), value: "-" }
  ], [item]);


  const coverageCountriesNamesList = useMemo(() => {
    const countries = item?.coverageCountries?.map((item) => {
      return item.name;
    }) || [];
    return countries.join(', ');
  }, [item])

  const toggleShowMap = () => {
    setShowCoverageMap(show => !show);
  }

  return (

    <Box style={styles.modalStyles}>

      <Grid container columnSpacing={{ xs: 1, sm: 1, md: 2 }}>
        {/*Details section*/}
        <Grid item>
          <Grid>
            <MainCard
              sx={{
                width: "304px",
                border: "none",
                padding: "10px",

              }}
            >
              <Stack spacing={2}>

                <Typography
                  variant="body1"
                  color="#111111"
                  sx={{
                    height: "15px",
                    overflow: "hidden",      // Hide overflow
                    whiteSpace: "nowrap",    // Prevent text from wrapping
                    textOverflow: "ellipsis" // Add ellipsis for overflow
                  }}
                  style={styles.typographyStyles}
                >
                  {item?.name}
                </Typography>

                <Box>
                  <Typography
                    variant="p"
                    style={styles.pricePerMbStyles}
                  >
                    $  {tokenService.data.hidePlanPrice ? '-' :
                      item?.price
                    } / {item?.volumeUnit ? UnitLabels[item?.volumeUnit] : ''}
                  </Typography>
                </Box>

                <Stack spacing={3}>
                  <Typography
                    variant="p"
                    color="#7A7B84"
                    sx={styles.accessPriceStyles}
                  >
                    {t('views.plan_details.access')}: $ {tokenService.data.hidePlanPrice ? '-' : `${item?.networkAccessPrice ?? '0.0'}`} / mo
                  </Typography>


                  {/*images Container*/}
                  <ImageContainer images={item?.planLogoUrls} />

                  <Stack spacing={2}>
                    <KeyValueList list={planPriceInfo} />
                  </Stack>
                  <Stack spacing={3}>
                    <Actions {...actionsProps} />
                  </Stack>
                </Stack>
                {item?.newPlan ? <Box
                  style={{
                    position: "absolute",
                    left: "57%",
                    top: "-10px",
                    backgroundColor: "#5264C9", // Background color
                    width: "200px",
                    height: "28px",
                    transform: "rotate(35deg)", // Rotate the label 35 degrees clockwise
                    display: "flex",
                    justifyContent: "center",
                    aligndatas: "center",
                    color: "white", // Text color
                    fontFamily: "Inter",
                    weight: 600
                  }}
                >
                  New
                </Box> : ""}
              </Stack>

            </MainCard>
          </Grid>

          <Grid item style={{ marginTop: "25px" }}>
            <MainCard sx={{
              backgroundColor: "#fff",
              width: "100%",
              borderRadius: "8px",
              border: "none"
            }}>
              <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography style={styles.detailsHeaderStyles} variant="h6">{t('views.plan_details.coverage')}</Typography>
                <Button onClick={toggleShowMap}>{t('views.plan_details.see_map')}</Button>
              </Box>
              <Box style={{ maxHeight: '100px', overflowY: 'auto', width: '270px' }}>
                <Typography style={{ ...styles.detailsValueStyles }} variant="h6">
                  {coverageCountriesNamesList}
                </Typography>
              </Box>

            </MainCard>
          </Grid>

        </Grid>
        <Grid item flex={1}>
          {/*Description section*/}
          <Grid item>
            <MainCard
              sx={{
                backgroundColor: "#fff",
                width: "100%",
                px: 2
              }}
            >
              <Stack sx={{ maxHeight: "60px", overflowY: "auto" }}>
                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography style={styles.detailsHeaderStyles} variant="h6">{t('views.plan_details.description')}</Typography>
                </Box>
                <Typography marginTop={"10px"} sx={{
                  backgroundColor: "#fff",
                }}>
                  {item?.description}
                </Typography>
              </Stack>
            </MainCard>
          </Grid>
          {/*KeyValueList section*/}
          <Grid item style={{ marginTop: "25px" }}>
            <MainCard
              sx={{
                backgroundColor: "#fff",
                width: "100%",
                p: 2
              }}
            >
              <Stack spacing={2}>
                <KeyValueList list={planDescriptionInfo} />
              </Stack>
            </MainCard>
          </Grid>
          <Grid item style={{ marginTop: "25px" }}>

            <MainCard
              sx={{
                backgroundColor: "#fff",
                width: "100%",
                height: "291px",
                borderRadius: "8px",
                overflow: 'hidden'
              }}
            >
              <Typography style={styles.detailsHeaderStyles} variant="h6">{t('views.plan_details.coverage_map')}</Typography>
              <Box style={{ marginTop: '-48px' }}>
                <CoverageMap item={item?.coverageCountries} />
              </Box>
            </MainCard>
          </Grid>
          {/* Label on the right side, moved further to the right */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PlanDetails;
