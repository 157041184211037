import { Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import Permissions from "const/Permissions";
import usePermissions from "hooks/usePermissions";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const styles = {
    checkboxContainer: {
        display: "flex",
        alignItems: "center"
    },
    checkbox: {
        marginRight: "0", // Remove right margin
        padding: "0"     // Remove padding
    }
};

const PlanInstallAction = ({ data, openInstallModal = () => { } }) => {
    const permissions = usePermissions();

    const { t } = useTranslation();
    const [fallback, setFallback] = useState(false);
    return <>
        <Box style={{ display: "flex", justifyContent: "center", marginTop: "25px" }}>
            <Button
                variant="contained"
                disabled={!permissions.hasAll(Permissions.MarketplaceSection.ChangePlanAction)}
                onClick={() => openInstallModal({ ...data, fallback })}
                sx={{
                    width: "256px",
                    height: "46px",
                }}
            >
                {t('views.plan_details.install')}
            </Button>
        </Box>

        <Box style={styles.checkboxContainer} mt={1}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={fallback}
                        onChange={(event) => setFallback(event.target.checked)}
                        className={styles.checkbox} // Apply custom checkbox class
                    />
                }
                label={t('views.plan_details.set_fallback')}
            />
        </Box>
    </>
}

export default PlanInstallAction;