import api from '../api';

const removeIp = async (params = {}) => {
    const { data } = await api.post('/api/meerkat-vpn-operations/remove-ip', {}, { params });
    return data;
};

const assignIp = async (params = {}) => {
    const { data } = await api.post('/api/meerkat-vpn-operations/assign-ip', {}, { params });
    return data;
};

const getMeerkatListByEid = async (eid, params) => {
    const { data } = await api.get(`/api/meerkat-vpn-operations/list/${eid}`, { params });
    return data;
};

const MeerkatService = {
    getMeerkatListByEid,
    removeIp,
    assignIp
};

export default MeerkatService;
