import PropTypes from 'prop-types';
import { Box, Divider, Skeleton, Stack, styled } from '@mui/material';

const ListItem = styled(Stack)(({ theme }) => ({
    '&': {
        lineHeight: 'normal'
    },
    '& .name, .value': {
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '152%'
    },
    '& .name': {
        color: '#7A7B84'
    },
    '& .value': {
        color: '#111111',
        verticalAlign: 'text-bottom',
        position: 'relative'
    },
    '& .value > *': {
        display: 'flex',
        alignItems: 'end'
    }
}));

const KeyValueListItem = ({ name, value, loading }) => {
    const val = typeof value === 'function' ? value() : value;
    return (
        <ListItem direction={'row'} spacing={0} alignItems={'baseline'}>
            {loading ?
                <Skeleton width={'80px'} variant={'rectangular'}>
                    <Box className="name" sx={{ height: '20px' }}></Box>
                </Skeleton> : <>
                    <Box className="name">{name}</Box>
                </>
            }
            <Box style={{ flex: 1, padding: '0 8px' }}>
                <Divider sx={{ backgroundColor: '#ececec', height: '1px' }} />
            </Box>
            {loading ?
                <Skeleton width={'80px'} variant={'rectangular'}>
                    <Box className="value" sx={{ height: '20px' }}></Box>
                </Skeleton> : <>
                    <Box className="value">{val}</Box>
                </>
            }
        </ListItem>
    );
};

KeyValueListItem.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
};

const KeyValueList = ({ list, loading = false, ...props }) => {
    return (
        <Stack spacing={2} {...props}>
            {list.map(({ name, value }) => (
                <KeyValueListItem key={name} name={name} value={value} loading={loading} />
            ))}
        </Stack>
    );
};

KeyValueList.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
        })
    ).isRequired
};

export default KeyValueList;
