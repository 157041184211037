import NoResults from 'components/Error/NoResults';

const defaultOptions = {
    component: NoResults,
    emptyDataConditionCallback: params => !params.isLoading && params?.data?.length === 0
};

const withEmptyData = (Component, options = defaultOptions) => {
    const usedOptions = { ...defaultOptions, ...options };
    const NoResultsComponent = usedOptions.component;
    return props => {
        return (
            <>
                {usedOptions.emptyDataConditionCallback(props) ? <NoResultsComponent  {...props} /> : <Component {...props} />}
            </>
        )
    }
}

export default withEmptyData;