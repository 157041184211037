import api from '../api';

const getStoreItems = async (body) => {
    try {
        const { data } = await api.post('/api/get-store-items', body);
        return data;
    } catch (error) {
        throw error;
    }
};

const StoreService = {
    getStoreItems
};

export default StoreService;
