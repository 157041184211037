import PropTypes from 'prop-types';

import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

const SimpleSelect = ({
    label,
    multiple,
    menuItems,
    selectedFilters,
    onMenuItemSelect,
    getOptionValue,
    getOptionLabel,
    loading,
    ...props
}) => {

    const makeOption = (option) => {
        return {
            label: getOptionLabel(option),
            value: getOptionValue(option)
        }
    }

    const handleChange = (event) => {
        let option = event.target.value;

        const options = multiple ? option.map(o => makeOption(o)) : makeOption(option);
        onMenuItemSelect(options);
    };

    const selected = useMemo(() => {
        return multiple
            ? menuItems.filter((option) =>
                selectedFilters.some((choice) => getOptionValue(option) === choice)
            )
            : menuItems.find((option) => getOptionValue(option) === selectedFilters);
    }, [menuItems, selectedFilters]);


    return (
        <FormControl fullWidth>
            <InputLabel id={label}>{label}</InputLabel>
            <Select
                labelId={label}
                multiple={true}
                value={selected}
                onChange={handleChange}
                label={label}
                fullWidth
                sx={{

                    lineHeight: "auto",
                    height: "40px"
                }}
                {...props}
            >
                {loading ? <MenuItem>
                    Loading
                </MenuItem> :
                    menuItems.filter(option => !selected.some(selected => getOptionValue(option) === getOptionValue(selected))).map((menuItem) => (
                        <MenuItem key={getOptionValue(menuItem)} value={menuItem}>
                            {getOptionLabel(menuItem)}
                        </MenuItem>
                    ))

                }
            </Select>
        </FormControl>
    );
};


SimpleSelect.propTypes = {
    menuItems: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    multiple: PropTypes.bool,
    getOptionValue: PropTypes.func,
    getOptionLabel: PropTypes.func,
    transform: PropTypes.func,
    onOpen: PropTypes.func,
    onSearch: PropTypes.func,
    onSelect: PropTypes.func,
    selectedFilters: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.any)])
};

SimpleSelect.defaultProps = {
    loading: false,
    multiple: false,
    getOptionValue: (option) => option,
    getOptionLabel: (option) => option,
};

export default SimpleSelect;
