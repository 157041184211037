import { useMemo } from "react";
import { Box, Stack } from "@mui/material";
import CustomTooltip from "components/shared/tooltip";


const TruncateList = ({
    data,
    limit = 3,
    transform = (data) => data,
    renderVisible = (data) => data,
    renderHidden = (data) => data,
    defaultValue = '',
    showTooltip = true,
    className = '',
    sx = {}
}) => {
    const { shownData, hiddenData } = useMemo(() => {
        const newData = data?.map(transform);
        const shownData = newData?.slice(0, limit);
        const hiddenData = newData?.slice(limit);
        return { shownData, hiddenData };
    }, [data]);

    const hiddenCount = hiddenData.length;

    return <Stack className={className} direction={'row'} sx={sx} style={{ textWrap: 'nowrap' }} spacing={1} flexWrap='nowrap'>
        {data?.length === 0 ? defaultValue :
            <>
                <Box>
                    {shownData.map(renderVisible)}
                </Box>
                <Box>
                    <CustomTooltip
                        disableHoverListener={!showTooltip}
                        title={hiddenData.map(renderHidden)}
                        arrow={true}
                        placement={'top-start'}
                    >
                        <Box className='TruncateListHiddenCount'>
                            {hiddenCount === 0 ? '' : `+${hiddenCount}`}
                        </Box>
                    </CustomTooltip>
                </Box>
            </>
        }
    </Stack>
}

export default TruncateList;
