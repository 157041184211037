import { Box, Dialog, IconButton, Slide, Stack } from '@mui/material'
import { forwardRef, useEffect } from "react";
import { styled } from '@mui/material/styles';
import T from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';
import ModalBreadCrumbs from "modules/modals/PlanDetails/components/ModalBreadcrumbs";

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: '8px',
        p: 2
    },
    '& .MuiDialogActions-root': {
        gap: theme.spacing(1),
        marginLeft: '0 !important'
    },
    '& .ModalContentWrapper-root': {
        padding: '16px',
        overflow: 'hidden'
    }
}));

const Modal = ({
    className,
    maxWidth,
    fullWidth,
    sx,
    Component,
    close,
    props,
    modal,
    title
}) => {


    const breadcrumbs = (typeof title === 'string') ? [
        {
            name: '',
            title
        }
    ] : title;

    return <StyledDialog
        open={true}
        transition={Transition}
        className={`modal ${className}`}
        fullWidth={fullWidth}
        keepMounted={false}
        maxWidth={maxWidth}
        sx={sx}
        onClose={modal.closeModal}
    >
        <Stack className={'ModalContentWrapper-root'}>
            <Stack direction={'row'} justifyContent='space-between' pb={1}>
                {breadcrumbs?.length > 0 &&
                    <Box><ModalBreadCrumbs breadCrumbs={breadcrumbs} /></Box>
                }
                {close &&
                    <IconButton
                        onClick={modal.closeModal}
                        sx={{
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <CloseOutlined />
                    </IconButton>
                }
            </Stack>
            <Component {...props} modal={{ closeModal: modal.closeModal }} />
        </Stack>
    </StyledDialog>
}

Modal.propTypes = {
    Component: T.element.isRequired,
    children: T.any,
    className: T.string,
    maxWidth: T.string,
    fullWidth: T.bool,
    sx: T.object,
    close: T.bool,
    props: T.object,
    title: T.any
};

Modal.defaultProps = {
    children: '',
    className: '',
    maxWidth: 'xs',
    fullWidth: true,
    sx: {},
    close: false,
    props: {}
};

export default Modal;

