import PropTypes from 'prop-types';
import useJobProgress from 'hooks/useJobProgress';
import CircularProgressLoader from 'components/shared/loader/CircularProgressLoader';
import { useEffect, useState } from 'react';
import { JobProgressStatus } from 'const/JobProgress';
import { LinearProgress } from '@mui/material';
import { differenceInSeconds } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const WARNING_TIMEOUT = 10;
const JobProgressLoader = ({ requestId, onFinish, type, sx, ...props }) => {
    const { data, isError, isSuccess, isFinished, error } = useJobProgress(requestId);
    const [progress, setProgress] = useState(0);
    const [operationStartTime] = useState(new Date());
    const timeElapsedInSeconds = differenceInSeconds(new Date(), operationStartTime);
    const { t } = useTranslation();

    const { enqueueSnackbar } = useSnackbar();

    const showWarning = timeElapsedInSeconds >= WARNING_TIMEOUT;

    useEffect(() => {
        if (isFinished) {
            onFinish(isError ? JobProgressStatus.Failed :
                isSuccess ? JobProgressStatus.Finished : JobProgressStatus.Created,
                { data, error });
            return;
        }
        if (showWarning) {
            enqueueSnackbar(t("views.onechip_cards.actions.general_message"), { variant: "warning", persist: true });
        }
    }, [isFinished, showWarning]);

    useEffect(() => {

        if (data?.progress) {
            setProgress(data.progress);
        }
    }, [data?.progress]);


    return (
        <>
            {type === 'circular' && <CircularProgressLoader {...props} sx={{ color: '#151F57', ...sx }} value={progress} />}
            {type === 'linear' && <LinearProgress {...props} sx={sx} variant="determinate" value={progress} />}
        </>
    );
};

JobProgressLoader.propTypes = {
    requestId: PropTypes.string.isRequired,
    onFinish: PropTypes.func,
    type: PropTypes.oneOf(['circular', 'linear']),
    sx: PropTypes.object
};

JobProgressLoader.defaultProps = {
    onFinish: () => { },
    type: 'circular',
    sx: {}
};

export default JobProgressLoader;
