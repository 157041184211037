import PropTypes from "prop-types";

// material-ui
import { Avatar, Box, Button, Card, Divider, Skeleton, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import PlanLogo from "./PlanLogo";
import { UnitLabels } from "../../const/UnitLabels";
import TokenService from "services/token/Token";
import TruncateList from "components/shared/list/TruncateList";

const ErrorStyle = {
  fontSize: "14px",
  lineHeight: "17px",
  "& .MuiBox-root": {
    mt: 1
  },
  "& img": {
    width: "22px"
  }
};



const CountryCoverageList = ({
  data,
  limit = 4
}) => {
  const renderVisible = (data, index, arr) => {

    const total = arr.length;
    let value = data;
    const lastIndex = Math.min(total, limit);
    if (index !== lastIndex - 1) {
      value += ', ';
      return value;
    }

    return value;
  }

  const renderHidden = (data, index, arr) => {
    const total = arr.length - 1;
    let value = data;
    if (total >= 1 && index < total) {
      value += ', ';
    }
    return value;
  }
  return <TruncateList
    limit={limit}
    data={data}
    renderVisible={renderVisible}
    renderHidden={renderHidden}
    transform={(data) => data.isoCode}
    defaultValue={'-'}
    showTooltip={false}
  />

}

const PlansContent = ({ item, loading, children }) => {
  const tokenService = TokenService.getData();


  const {
    planLogoUrls,
    planName,
    planPrice,
    volumeUnitLabel,
    countryCoverage,
    networkAccessPrice,
    newPlan
  } = useMemo(() => {
    return {
      planLogoUrls: item?.planLogoUrls ?? [],
      planName: item?.name ?? '',
      planPrice: item?.price,
      volumeUnitLabel: UnitLabels[item?.volumeUnit],
      networkAccessPrice: item?.networkAccessPrice ?? 0.0,
      countryCoverage: item?.coverageCountries,
      newPlan: item?.newPlan
    }
  }, [item]);


  return (
    <Stack spacing={2}>

      <Stack
        spacing={2}
        alignItems={"center"}
        sx={{ paddingRight: "20px" }}
        width={"100%"}
        direction="row">

        {loading ? <Skeleton variant="circular">
          <Avatar sx={{ width: '88px', height: '88px', borderRadius: 0 }}></Avatar>
        </Skeleton> :
          <>
            {
              planLogoUrls && planLogoUrls.length > 0 ? (
                // Render the PlanLogo component or whatever content you want when planLogoUrls is defined and not empty
                <PlanLogo planLogoUrls={planLogoUrls} />
              ) : (
                // Render an alternative component or message when planLogoUrls is empty or undefined
                <div style={{ width: "90px", height: "90px", borderRadius: "4px", border: "1px" }} />
              )
            }
          </>
        }

        <Divider orientation="vertical" flexItem /> {/* Vertical line divider */}
        <Stack spacing={2}>
          {/* Display the plan name using Typography */}
          {loading ?
            <Skeleton width={'100px'} height={'20px'} variant="rectangular"></Skeleton>
            :
            <Typography
              color="textSecondary"
              title={planName}
              sx={{
                fontSize: "18px", // Updated font size to match Figma
                fontWeight: 600,
                lineHeight: "22px",
                fontFamily: "Inter",
                letterSpacing: "0px",
                // textAlign: 'left',
                whiteSpace: "nowrap",
                overflow: 'hidden',
                maxWidth: '345px',
                textOverflow: 'ellipsis',
                textAlign: 'left',
                color: "#151F57", // Text color
              }}
            >
              {!loading && planName}
            </Typography>
          }

          {/* Country */}
          {loading ?
            <Skeleton width={'50px'} height={'20px'} variant="rectangular"></Skeleton>
            :
            <Typography
              variant="p"
              color="textSecondary"
              sx={{
                fontSize: "16px", // Updated font size to match Figma
                fontWeight: 500,
                lineHeight: "19px",
                fontFamily: "Inter",
                letterSpacing: "0px",
                textAlign: "left",
                whiteSpace: "nowrap",
                color: "#111111",
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '300px'
              }}
            >
              <CountryCoverageList data={countryCoverage} />
            </Typography>
          }

          {/* Amount of Megabytes */}
          <Stack direction='row' spacing={1}>
            {loading ?
              <Skeleton width={'100px'} height={'20px'} variant="rectangular"></Skeleton>
              :
              <>

                <Typography
                  variant="p"
                  color="textSecondary"
                  sx={{
                    fontSize: "20px",
                    fontWeight: 700,
                    lineHeight: "24px",
                    fontFamily: "Inter",
                    color: "#151F57",
                  }}
                >
                  {tokenService.data.hidePlanPrice ? '-' : planPrice}/{volumeUnitLabel}
                </Typography>

              </>
            }
            {loading ?
              <Skeleton width={'100px'} height={'20px'} variant="rectangular"></Skeleton>
              :
              <>

                <Typography
                  variant="p"
                  color="textSecondary"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    whiteSpace: "nowrap",
                  }}
                >Access: {tokenService.data.hidePlanPrice ? '-' : networkAccessPrice}/mo
                </Typography>

              </>
            }
          </Stack>
          {/* Label on the right side, moved further to the right */}
          {newPlan ? <Box
            style={{
              position: "absolute",
              top: "5px",
              left: "65%", // Increase the distance from the right edge
              backgroundColor: "#5264C9", // Background color
              width: "234px",
              height: "28px",
              transform: "rotate(35deg)", // Rotate the label 35 degrees clockwise
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white", // Text color
              fontFamily: "Inter",
              weight: 600
            }}
          >
            New
          </Box> : ""}

        </Stack>

      </Stack>
      <Stack direction='row' flex={1} width={'100%'} spacing={2} sx={{ "& > :empty": { display: 'none' } }}>
        {children}
      </Stack>
    </Stack >
  );
};

const DataComponent = ({ isRecommendation, item, onClick, isLoading, ...props }) => {
  let backgroundColorParam = isRecommendation.isRecommendation ? "transparent" : "#fff";

  const handleOnClick = (event) => {
    event.stopPropagation();
    onClick(event);
  }

  return (
    <Card

      sx={{
        backgroundColor: backgroundColorParam,
        maxWidth: "500px",
        width: "100%",
        p: 2,
        border: "1px solid #ECECEC", // Remove the border
        "&:focus, &:hover": {
          backgroundColor: "#F0FAF9"
        }
      }}
      onClick={handleOnClick}
    >
      <PlansContent
        item={item}
        loading={isLoading}
        {...props}
      />
    </Card>
  );
};

DataComponent.propTypes = {
  isLoading: PropTypes.bool
};

DataComponent.defaultProps = {
  isLoading: false,
};

export default DataComponent;
