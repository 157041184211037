import { Box, styled } from "@mui/material";
import WorldMapChart from "components/charts/WorldMapChart";

const Tooltip = styled('div')({
  padding: "0.5rem",
  border: "1px solid silver",
  color: "#222",
  backgroundColor: "#fff",
  borderRadius: "5px",
  marginTop: "-80px",
  marginLeft: "-80px",
  boxShadow: "0px 0px 5px 0px #a6a6a6",
  whiteSpace: "nowrap",
  "span": {
    fontWeight: "bold"
  }
});


const CoverageMap = ({ item }) => {

  return <>
    <WorldMapChart
      responsive={true}
      defaultCountryColor="#D8D8D8"
      hoverBorderColor="#FFFFFF"
      hoverColor="#C8C8C8"
      dataConfig={{
        isoCode: "isoCode",
        details: (params) => ({
          fillColor: "#9dcce5",
          highlightFillColor: "#151F57",
          ...params
        })
      }}
      data={item}
      popupTemplate={(geo, data) => {
        return <Tooltip>{geo.properties.name}</Tooltip>
      }}
    />
  </>
}

export default CoverageMap;
